body {
    background-image: linear-gradient(180deg, #eee, #fff 100px, #fff);
    font-family: 'Source Sans Pro', sans-serif;
}

.btn-slot-machine,
.btn-slot-machine:hover {
  background-color: #FF0096;
  color: white;
  border-radius: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-weight: bold;
  font-size: 3rem;

}
.slotmachine-emoji, .island-emoji {
  vertical-align: text-bottom;
  margin-right: .5rem;
  height: 60px;
}

.btn-random-pfp,
.btn-random-pfp:hover {
  background-color: #FF0096;
  color: white;
  font-weight: bold;
}

.block-header {
  position: relative;
}

.block-header   a.view-all {
  position: absolute;
  right: 0;
}

.sodabits-block {
  width: 100%;
  min-height: 100px;
}

.sold-block {
  background: #f4b9d7;
}

.free-block {
  background: #a6ffa1;
}

tr.sodabits-block-row,
tr.sodabits-block-row td {
  border-style: none;
}

tr.sodabits-block-row a {
  display: inline-block;
  width: 99px;
}


img.img-fluid.pixelated.header-placeholder {
    position: relative;
    width: 100%;
    z-index: -100;
    top: 0;
    left: 0;
    margin: -150px 0 0 0;
    padding: 0;
}


img.logo {
    max-width: 100%;
}

a {
    color: #FF0096;
    text-decoration: none;
}

.card-detail {
    background-image: linear-gradient(180deg, #816FF9, #F46EB1 300px);
    min-height: 420px;

}

.text-left {
    text-align: left;
    padding-left: 2em;
}

.text-right {
    text-align: right;
}

.strong {
    font-weight: 800;
}

img.detail {
    vertical-align: middle;
    min-width: 180px;
    margin: auto;
    image-rendering: pixelated;
}

img.pixelated {
    width: 100%;
    image-rendering: pixelated;
}

.container {
    max-width: 1200px;
}

ul {
    list-style: none;
}
ul.raffle-rules ,
ul.raffle-rules li
{
    list-style: initial;
    text-align: left !important;
}

.btn-cs {
    background: #FF0096;
    border-radius: 8px;
    color: #fff;
    border: none;
    padding: .7em;
}

.text-xl {
    font-size: x-large;
}

.footer-side {
    padding: 4em;
}

footer,
.footer {
    background: #000;
    width: 100%
}

.sticky-footer {
    background: rgba(255,255,255,.9);
    border-radius: .5rem;
    color: black;
    height: 4rem;
    max-width: 14rem;
    margin-right: 3rem;
    box-shadow: 1px -1px 2px 0px #CECECE,
               -3px 3px 3px 1px #CECECE;
}

.sticky-footer > * {
  margin-top: .25rem;
  margin-left: .75rem;
}

.sticky-footer *:first-child {
  margin-left: 0;
}

.sticky-footer img {
  margin-top: .25rem;
  image-rendering: pixelated;
}

.sticky-footer .btn-close {
  position: absolute;
  font-weight: bold;
  padding: 0;
  margin: 0;
  margin-left: .5rem;
  height: .75rem;
  width: .75rem;
}

a.link-secondary.text-decoration-none {
    color: #fff;
}


    a.white-link {
        color: #fff;
       
    }

@media (max-width: 768px) {

    * {
        text-align: center !important;
    }

    .text-left {
        padding: unset !important;
    }

    img.logo {
        margin-top: -20px;
    }

    a.white-link {
        color: #fff;
        padding: .5em;
        font-size: smaller;
        height: 80px;
    }

    a.link-secondary.text-decoration-none {
    color: #fff;
    font-size: 22px;
    line-height: 1.85;
    width: 100vw;
  }
}

span.name, span.avail {
    color: #ff0094;
}

.table {
    font-size: large;
    font-weight: 800;
    vertical-align: middle;
}

td.table-img {
    min-width: 66px;
    margin: .25em 1.2em;
}
td.table-img  a {
    width: 66px;
    display: inline-block;
    text-align: center;
    text-decoration: underline;
}
td.table-img  img {
    width: 100%;
    image-rendering: pixelated;
}

.lazy-load-image-background {
  width: 100%;
}

.lazy-load-image-background:not(.lazy-load-image-loaded) {
  display: block!important;
  width: 66px;
  height: 146px;
}

.twitter-tweet:not(.twitter-tweet-rendered) {
  visibility: hidden;
}

.twitter-tweet {
  margin: 0 auto;
}

@media (max-width: 768px) {

    td.table-img {
        max-width: 35px;
        margin: .3em;
    }

}
